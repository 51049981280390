import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, getUser } from '@data/redux/usersSlice';
import Loading from '@components/Loading';
import { createDocument } from "@data/firebase/firestore/saveData";
import { logoutUser } from "@data/firebase/auth";
import { logoutUserData } from '@data/redux/usersSlice';
import { useNavigate } from 'react-router-dom'; 
import Practice from "@pages/Admin/Home/Practice/Simple";
import emailjs from '@emailjs/browser';

//const stripePromise = loadStripe('pk_test_51PkK2CFC74FNBNDV0ft0Cd1gRdyKnxOGlO2wc4ql5czxJtF5FusWzUOGOr7fHZpt0sZknLBgmwJmWaOHuVDinbBh000nRL7L30');
const stripePromise = loadStripe('pk_live_51PkK2CFC74FNBNDVHLLVAXbSfl4gbcifdglgPlWMzVFru54utK9IA3nvCIAHfxSSEnV43OxnqmMVfmJdWyU4amAO002B1LNP7b');

const Subscribe = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const user_store = useSelector(getUser);
  const navigate = useNavigate(); 

  const createPayment = async (period) => {
    setLoading('Redirecting to payment...');
    const stripe = await stripePromise;
    console.log('Creating payment');
    try {
      // Call your backend to create the Checkout session
      const response = await fetch('https://metromotivator-stripe.vercel.app/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ period, trial_days: 3 }),
      });
      console.log('Creating session...');
      const session = await response.json();
      console.log('Response sessions');
      console.log(session);
      if (session.id) {    
        // Redirect to Stripe Checkout using the session ID
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          setError(result.error.message);
        }
      } else {
        throw new Error('Failed to create session');
      }
    } catch (error) {
      setError('Error creating payment session: ' + error.message);
      setLoading(false);
    }
  };

  const checkSubscriptionStatus = async () => {
    console.log('Check status');
    try {
      const response = await fetch(`https://metromotivator-stripe.vercel.app/check-subscription-status?subscription_id=${user_store.subscriptionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('status response');
      console.log(response);

      const data = await response.json();
      console.log(data);
      if (data.status === 'active' || data.status === 'trialing') {
        dispatch(setUser({ ...user_store, subscription: 'active', status_type: data.status }));
      } else {
        dispatch(setUser({ ...user_store, subscription: 'inactive' }));
      }
      setLoading(false);
    } catch (error) {
      console.error("Error checking subscription status:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading('Checking payment...');
    if (user_store.promo === 'GOLD') {
      dispatch(setUser({ ...user_store, subscription: 'active' }));
    } else {
      if (user_store.subscriptionId) {
        checkSubscriptionStatus();
      } else {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    setLoading('Checking payments...');
    const query = new URLSearchParams(window.location.search);
    const type = query.get('type');
    const sessionId = query.get('session_id');
    console.log('Use Effect...');
    if (type === 'cancel') {
      setLoading(false);
      setMessage('Payment has been cancelled');
      console.log('Payment has been cancelled');
    } else if (type === 'success' && sessionId) { 
      console.log('succes', sessionId);
      fetch(`https://metromotivator-stripe.vercel.app/get-checkout-session?session_id=${sessionId}`)
      .then(response => response.json())
      .then(data => {
        setLoading('Payment recieved, activating account...');
        console.log('data:', data);
        if (data && data.subscription) {
          createDocument('users', { subscriptionId: data.subscription }, async (response) => {
            setLoading('User active...');
            console.log('users response', response);
            if (response.type === 'success') {
              emailjs.send("service_b92rfmi","template_f1htzgq",{
                from_name: "Metromotivator",
                to_name: user_store.firstName+' '+user_store.lastName, 
                reply_to: user_store.email, 
                message: '',
                period: 'monthly'
              }, 'RHWBIG4YJx5pJDdTD').then((result) => {
                dispatch(setUser({ ...user_store, subscriptionId: data.subscription, subscription: 'active' }));
                setLoading(false);
              }, (error) => {
                console.log(error);
                setMessage('E-mail service failed');
              })
            }
          }, user_store.email,true);  
        }
      })
    } else {
      setLoading(false);
    }
  }, [window.location]);

  const logoutSubmit = async () => {
    try {
      await logoutUser();
      dispatch(logoutUserData()); 
      navigate('/'); 
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  return (
    <div id="subscribe">
      <a href="#" title="Logout" className={`btn-logout-main`} onClick={logoutSubmit}>Logout</a>
      <div className="main-content">
        <div className="subscriptions">
          {message && <p style={{ color: 'red' }}>{message}</p>}
          <div className="sub active">
            <h3>Annual</h3>
            <span className="description">For serious musicians</span>
            <h2>$60</h2>
            <span className="description">per Year</span>
            <ul className="features">
              <li>Get two months free!</li>
            </ul>
            {/*<ul className="features">
              <li>Unlimited minutes</li>
              <li>Upload audio</li>
              <li>Track monthly progress</li>
              <li>Premium drills</li>
            </ul>*/}
            <button className="btn-main btn-subscribe" onClick={() => createPayment('yearly')} disabled={loading}>
              Subscribe Yearly
            </button>
          </div>
          <div className="sub">
            <h3>Monthly</h3>
            <span className="description">For the dabblers</span>
            <h2>$6</h2>
            <span className="description">per Month</span>
             {/*<ul className="features">
              <li>60 minutes max/day</li>
              <li>Upload tabs</li>
            </ul>*/}
            <button className="btn-main btn-subscribe" onClick={() => createPayment('monthly')} disabled={loading}>
              Subscribe Monthly
            </button>
          </div>
          {/*<div className="timer-wrapper subscription">
            <Practice />
          </div>*/}
        </div>
      </div>
      {loading && <Loading loading={loading} />}
    </div>
  );
};

export default Subscribe;
