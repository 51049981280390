import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '@data/redux/usersSlice';
import Popup from "@components/Popup";
import { getCollection } from '@data/firebase/firestore/getData';
import { createDocument } from '@data/firebase/firestore/saveData';
import { deleteDocument } from '@data/firebase/firestore/deleteData';
import { ThemeProvider } from '@mui/material/styles';
import UiTheme from '@data/themes';
import Loading from "@components/Loading";
import DeletePopup from "@components/DeletePopup";

import DrillForm from "./Components/DrillForm";
import DrillTable from "./Components/DrillTable";
import { checkDrillData } from "@data/helpers";
import { v4 } from "uuid";

const AdminLibrary = () => {
  const [popupAddDrill, setPopupAddDrill] = useState(false);
  const [drills, setDrills] = useState([]);
  const [popupDeleteDrill, setPopupDeleteDrill] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedInstrument, setSelectedInstrument] = useState('All');
  const [saveData, setSaveData] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetData, setResetData] = useState(v4());

  const user_store = useSelector(getUser);

  useEffect(() => {
    getDrillData();
  }, []);

  const getDrillData = () => {
    getCollection(`users/${user_store.email}/drills`, (response) => {
      setDrills(response);
    });
  };

  const saveDrill = () => {
    if(checkDrillData(saveData)){
      let update = saveData.id ? true : false;
      let id = update ? saveData.id : v4();
      console.log(update, id, saveData);
      createDocument(`users/${user_store.email}/drills`, saveData, (response) => {
        console.log(response);
        setLoading('Drill Updated Successfully');
        setSaveData(false);
        getDrillData();
        setTimeout(() => {
          setPopupAddDrill(false);
          setLoading(false);
        }, 2000);
      }, id, update);
    } else {
      setError('Fill in all input fields');
    }
  };

  const deleteDrill = () => {
    deleteDocument(`users/${user_store.email}/drills`, popupDeleteDrill.id, () => {
      getDrillData();
      setPopupDeleteDrill(false);
    });
  };

  useEffect(() => {
    setSaveData(popupAddDrill);
  }, [popupAddDrill]);


  return (
    <ThemeProvider theme={UiTheme}>
      <div className="admin-library">
        <DrillTable
          drills={drills}
          setPopupAddDrill={setPopupAddDrill}
          setPopupDeleteDrill={setPopupDeleteDrill}
          role="user"
        />
        <Popup active={popupAddDrill} closePopup={() => setPopupAddDrill(false)} onChange={() => saveDrill()}>
          { loading ? <Loading loading={loading} /> : (
            <>
              <DrillForm
                drills={drills}
                resetData={resetData}
                saveData={saveData}
                setSaveData={setSaveData}
                setPopupDeleteDrill={setPopupDeleteDrill}
              />
              {error && <span className="error-msg">{error}</span>}
            </>
          )}
        </Popup>
        <DeletePopup
          subject={popupDeleteDrill.drill}
          title="Delete Drill"
          active={popupDeleteDrill.drill}
          onChange={deleteDrill}
          closePopup={() => { setPopupDeleteDrill(false); }}
        /> 
      </div>
    </ThemeProvider>
  );
};

export default AdminLibrary;

