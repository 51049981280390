import React, { useState, useEffect, useRef } from 'react';
import TextInput from "@components/Inputs/TextInput";
import { Autocomplete, TextField } from '@mui/material';
import RadioOptions from '@components/RadioOptions';
import UploadFile from "@components/UploadFile";
import { v4 as uuidv4 } from 'uuid';
import './DrillForm.scss';
import AudioSurfer from "@components/AudioSurfer";
import { useSelector } from 'react-redux';
import { getUser } from '@data/redux/usersSlice';

const DrillForm = ({ drills, setSaveData, saveData = false }) => {
  const user_store = useSelector(getUser);
  const [drillName, setDrillName] = useState('');
  const [drillBpm, setDrillBpm] = useState(10);
  const [drillCategory, setDrillCategory] = useState('');
  const [drillInstrument, setDrillInstrument] = useState('');
  const optionCategory = [
    { value: 'metronome', label: 'Metronome' },
    { value: 'audio', label: 'Audio' },
  ];
  const [category, setCategory] = useState('metronome');
  const [drillImage, setDrillImage] = useState('');
  const [drillAudio, setDrillAudio] = useState(false);

  const [drillLink, setDrillLink] = useState(''); // New state for link
  const [addDrillError, setAddDrillError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [audioSettings, setAudioSettings] = useState({ start: 0, end: 5 });

  const handleUploadComplete = (fileInfo) => {
    setDrillImage(fileInfo.url);
  };

  const uploadFileImageRef = useRef(null);
  const uploadFileAudioRef = useRef(null);

  useEffect(() => {
    const uniqueCategories = [...new Set(drills.map(drill => drill.category).filter(Boolean))];
    const cat_list = uniqueCategories.map(category => ({
      id: uuidv4(),
      label: category
    }));
    setCategories(cat_list);
    const uniqueInstruments = [...new Set(drills.map(drill => drill.instrument).filter(Boolean))];
    const instr_list = uniqueInstruments.map(instrument => ({
      id: uuidv4(),
      label: instrument
    }));
    setInstruments(instr_list);
  }, [drills]);

  useEffect(() => {
    setDrillName(saveData?.drill || '');
    setDrillBpm(saveData?.bpm || 10);
    setDrillCategory(saveData?.category || '');
    setDrillInstrument(saveData?.instrument || '');
    setCategory(saveData?.type || 'metronome');
    setDrillImage(saveData?.file || '');
    setDrillAudio(saveData?.audio || '');
    setDrillLink(saveData?.link || '');
  }, [saveData])

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const drillData = {
      drill: drillName,
      bpm: parseInt(drillBpm),
      category: drillCategory,
      instrument: drillInstrument,
      type: category,
      date: currentDate,
      user: user_store.email,
    };
    if(category === 'audio'){
      drillData.audio = drillAudio;
      drillData.end = audioSettings.end;
      drillData.start = audioSettings.start;
    } else {
      drillData.file = drillImage;
      drillData.link = drillLink;
    }
    if(saveData.id){
      drillData.id = saveData.id;
    }
    console.log(drillData);
    setSaveData(drillData);
  },[drillName, drillBpm, drillCategory, drillInstrument, category, drillImage, drillAudio, drillLink, audioSettings])

  return (
    <div id="drill-popup">
      <form>
        <TextInput value={drillName} label="Drill" onChange={(e) => setDrillName(e)} placeholder="Name of your Drill" />
        <TextInput value={drillBpm} label="BPM" type="number" onChange={(e) => setDrillBpm(e)} placeholder="Recommended BPM" />
        <div className="auto-drop">
          <Autocomplete
            freeSolo
            id="drill-category"
            options={categories}
            getOptionLabel={(option) => option.label || ''} // Display the category name
            renderInput={(params) => <TextField {...params} label="Category" variant="outlined" placeholder="Select or create new category"  InputLabelProps={{ shrink: true }} />}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setDrillCategory(newValue);
              } else if (newValue && newValue.inputValue) {
                setDrillCategory(newValue.inputValue);
              } else if (newValue) {
                setDrillCategory(newValue.label);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setDrillCategory(newInputValue);
            }}
            value={categories.find(option => option.label === drillCategory) || { label: drillCategory }}
            filterOptions={(options, params) => {
              const filtered = options.filter((option) =>
                option.label.toLowerCase().includes(params.inputValue.toLowerCase())
              );
              if (params.inputValue !== '' && !filtered.some(option => option.label === params.inputValue)) {
                filtered.push({
                  inputValue: params.inputValue,
                  label: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
          />
        </div>
        <div className="auto-drop">
          <Autocomplete
            freeSolo
            id="drill-instrument"
            options={instruments}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => <TextField {...params} label="Instrument" variant="outlined"  placeholder="Select or create new instrument"  InputLabelProps={{ shrink: true }} />}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setDrillInstrument(newValue);
              } else if (newValue && newValue.inputValue) {
                setDrillInstrument(newValue.inputValue);
              } else if (newValue) {
                setDrillInstrument(newValue.label);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setDrillInstrument(newInputValue);
            }}
            value={instruments.find(option => option.label === drillInstrument) || { label: drillInstrument }}
            filterOptions={(options, params) => {
              const filtered = options.filter(option =>
                option.label.toLowerCase().includes(params.inputValue.toLowerCase())
              );
              if (params.inputValue !== '' && !filtered.some(option => option.label === params.inputValue)) {
                filtered.push({
                  inputValue: params.inputValue,
                  label: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
          />
        </div>
        <div className="tabs">
          <div className={`tab ${(category=='metronome')?'active':''}`} onClick={()=>setCategory('metronome')}>Metronome</div>
          <div className={`tab ${(category=='audio')?'active':''}`} onClick={()=>setCategory('audio')}>Audio</div>
        </div>
        {(category === 'metronome') && (
          <div className="cat-box">
            <div className="cat-field">
              <TextInput value={drillLink} label="Link" onChange={(e) => setDrillLink(e)} placeholder="Enter Link" />
              { drillImage && (
                <div className="image-preview">
                  <img src={drillImage} alt="Drill Preview" style={{ width: '100%', height: 'auto', marginBottom: '10px' }} />
                </div>
              )}
            </div>
            <div className="cat-field">
              <label>Image</label>
              <UploadFile filepath={`/drills/${uuidv4()}`} onUploadComplete={(fileInfo) => setDrillImage(fileInfo.url)} ref={uploadFileImageRef} />
            </div>
          </div>
        )}
        {(category === 'audio') && (
          <div className="cat-box">
            <label>Audio file</label>
            { drillAudio && <AudioSurfer audioFile={drillAudio} audioSettings={audioSettings} setAudioSettings={setAudioSettings} /> }
            <UploadFile filepath={`/audio/${uuidv4()}`} fileType="audio" onUploadComplete={(fileInfo) => setDrillAudio(fileInfo.url)} ref={uploadFileAudioRef} />
          </div>
        )}
        {addDrillError && <span className="error-msg">{addDrillError}</span>}
      </form>
    </div>
  );
};

export default DrillForm;
