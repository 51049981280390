import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PieChart from "@components/Charts/PieChart";
import Popup from "@components/Popup";
import TextInput from "@components/Inputs/TextInput";
import Loading from "@components/Loading";
import { getUser, setUserGoal } from '@data/redux/usersSlice';
import { createDocument } from '@data/firebase/firestore/saveData';
import { getCollectionQueryBetween } from '@data/firebase/firestore/getData';
import { Timestamp } from "firebase/firestore";

const BoxGraph = () => {
  const user_store = useSelector(getUser);
  const [goal, setGoal] = useState(user_store.goal ? (user_store.goal / 60) : 0);
  const [popupGoal, setPopupGoal] = useState(false);
  const [goalError, setGoalError] = useState(false);
  const [goalLoading, setGoalLoading] = useState(false);
  const [done, setDone] = useState(0);

  const dispatch = useDispatch();

  const saveGoal = () => {
    setGoalLoading('Saving Goal');
    createDocument('users', { goal: parseInt(goal * 60) }, (response) => {
      setGoalLoading('Goal saved successfully');
      setTimeout(() => {
        dispatch(setUserGoal(parseInt(goal * 60)));
        setPopupGoal(false);
        setGoalLoading(false);
      }, 2000);
    }, user_store.email, true);
  };


  useEffect(() => {
    // Get start and end of today
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // Start of the day
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // End of the day

    // Convert them to Firestore Timestamps
    const startOfDay = Timestamp.fromDate(todayStart);
    const endOfDay = Timestamp.fromDate(todayEnd);

    // Use the query to get today's data
    console.log(startOfDay, endOfDay);
    getCollectionQueryBetween(
      'users/' + user_store.email + '/logs', 
      (response) => {
        // Summing up all seconds from the response
        const totalSeconds = response.reduce((accumulator, currentLog) => {
          return accumulator + (currentLog.seconds || 0); // Assuming seconds field is present in each log
        }, 0);

        // Convert total seconds to minutes
        const totalMinutes = Math.floor(totalSeconds / 60);

        // Set the total minutes
        setDone(totalMinutes);

        console.log(`Total seconds: ${totalSeconds}, Total minutes: ${totalMinutes}`);
      },
      {
        field: 'datetime', 
        value: { start: startOfDay, end: endOfDay }
      }
    );
  }, [])

  return (
    <div className="box graph">
      <Popup title="Set Goal" active={popupGoal} onChange={() => saveGoal()} closePopup={() => { setPopupGoal(false); }}>
        {goalLoading && <Loading loading={goalLoading} />}
        <form>
          <TextInput type="number" value={goal} label="Goal (minutes)" onChange={(e) => setGoal(e)} placeholder="How many minutes a day you want to play?" />
          {goalError && <span className="error-msg">{goalError}</span>}
        </form>
      </Popup>
      <div className="box-header">
        <span className="title">Today</span>
        <div className="action a-link" onClick={() => setPopupGoal(true)}>Edit Goal</div>
      </div>
      <div className="box-content">
        <div className="pie-details">
          <div className="done-todo">
            <span className="done">{ done }</span>
            <span className="todo">/ {goal}</span>
          </div>
          <span className="subline">min</span>
          <a href="/practice" className="btn-main small no-width">Start now</a>
        </div>
        <PieChart goal={goal} done={done} />
      </div>
    </div>
  );
};

export default BoxGraph;
