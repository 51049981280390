import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user_id: false,
  user: false,
  subscription: 'inactive', // Default subscription status
  subscriptionId: null, // Store Stripe subscription ID
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.user_id = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserGoal: (state, action) => {
      if (state.user) {
        state.user.goal = action.payload;
      }
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload.status;
      state.subscriptionId = action.payload.subscriptionId;
    },
    loginUserData: {
      reducer(state, action) {
        state.user_id = action.payload.user_id;
        state.user = action.payload.user;
        state.subscription = action.payload.subscription || 'inactive'; // default to inactive
        state.subscriptionId = action.payload.subscriptionId || null;
      },
      prepare(user, subscription = 'inactive', subscriptionId = null) {
        return {
          payload: {
            user_id: user.id,
            user: user,
            subscription,
            subscriptionId,
          },
        };
      },
    },
    logoutUserData: (state) => {
      state.user_id = false;
      state.user = false;
      state.subscription = 'inactive';
      state.subscriptionId = null;
    },
  },
});

export const { setUser, setUserId, setUserGoal, setSubscription, loginUserData, logoutUserData } = usersSlice.actions;
export const getUser = (state) => state.users.user;
export const getSubscriptionStatus = (state) => state.users.subscription;
export const getSubscriptionId = (state) => state.users.subscriptionId;

export default usersSlice.reducer;
