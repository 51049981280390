import { db } from "../";
import { collection, getDocs, query, where, orderBy, doc, getDoc } from "firebase/firestore";

// Function to get a specific document from a collection
export const getDocument = async (coll, id, callback) => {
  const docRef = doc(db, coll, id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    callback(docSnap.data());
  } else {
    callback("Document does not exist");
  }
};

// Function to get all documents from a collection
export const getCollection = async (coll, callback) => {
  const q = collection(db, coll);
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    let _data = doc.data();
    _data.id = doc.id;
    data.push(_data);
  });
  callback(data);
};

// Function to get documents from a collection with optional filtering and sorting
export const getCollectionQuery = async (coll, callback, filter = null, sort = null) => {
  let q = collection(db, coll);

  // Apply filter if provided
  if (filter && filter.field && filter.value !== undefined) {
    q = query(q, where(filter.field, "==", filter.value));
  }

  // Apply sorting if provided
  if (sort && sort.field && sort.order) {
    const orderDirection = sort.order.toLowerCase() === 'asc' ? 'asc' : 'desc';
    q = query(q, orderBy(sort.field, orderDirection));
  }

  // Fetch documents based on the query
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    let _data = doc.data();
    _data.id = doc.id;
    data.push(_data);
  });

  // Execute callback with the fetched data
  callback(data);
};

export const getCollectionQueryBetween = async (coll, callback, filter = null, sort = null) => {
  let q = collection(db, coll);

  // Apply filter if provided (support for range filtering)
  if (filter && filter.field && filter.value) {
    q = query(q, where(filter.field, ">=", filter.value.start), where(filter.field, "<=", filter.value.end));
  }

  // Apply sorting if provided
  if (sort && sort.field && sort.order) {
    const orderDirection = sort.order.toLowerCase() === 'asc' ? 'asc' : 'desc';
    q = query(q, orderBy(sort.field, orderDirection));
  }

  try {
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
      let _data = doc.data();
      _data.id = doc.id;
      data.push(_data);
    });

    // Execute callback with the fetched data
    callback(data);
  } catch (error) {
    console.error('Error fetching documents:', error);
  }
};