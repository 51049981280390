export const faqs = [
  { 
    question: 'What makes Metromotivator different from other metronome apps?',
    answer: 'Metromotivator combines a powerful metronome with motivational features to keep you engaged. It offers customizable sound patterns, tracks progress, and integrates goal-setting to help users stay focused and improve their practice routines.'
  },
  { 
    question: 'Can I create and upload my own exercises?',
    answer: 'Yes! Metromotivator allows you to create custom exercises tailored to your practice needs. You can also upload and share them with the community, or import exercises created by other users to keep your routine fresh and challenging.'
  },
  { 
    question: 'How does Metromotivator track my progress?',
    answer: 'Metromotivator tracks the duration and frequency of your practice sessions, as well as your tempo improvements over time. It provides detailed reports and charts that show your progress and help you identify areas where you need to focus more.'
  },
  { 
    question: 'Is Metromotivator suitable for all skill levels?',
    answer: 'Absolutely! Whether you’re a beginner or a seasoned musician, Metromotivator adapts to your skill level. You can start with simple exercises and gradually move to more complex patterns as you improve, making it a great tool for all levels.'
  },
  { 
    question: 'Can I use Metromotivator for instruments other than the guitar or piano?',
    answer: 'Yes! Metromotivator allows you to create custom exercises tailored to your practice needs. You can even upload your own tab - screenshots work great for this! - to ensure you’re working on your specific needs.'
  }
];