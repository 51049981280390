// soundFiles.js

// Define the sound file mapping for each drum type
import sound1 from '@assets/files/sounds/sound-1.wav';
import sound2 from '@assets/files/sounds/sound-2.wav';
import sound3 from '@assets/files/sounds/sound-3.wav';
import sound4 from '@assets/files/sounds/sound-4.wav';

const soundFiles = {
  bell: [sound1, sound2], // Bell sounds
  snare: [sound3, sound4], // Snare sounds
};

const metronomeSequence = {
  quarter: [1, 0, 0, 0], // 1st position for quarter rhythm
  triplet: [1, 0, 0], // 1st position for triplet rhythm
};

let intervalID = null; // Store the interval ID to clear it when stopping
let clickCount = 0; // Click counter to track which beat is playing
let context = null; // AudioContext for the playback

// Function to start or replace the sequence
function startSequence(drumType, rhythmType, bpm) {
  // Clear the previous sequence if it exists
  if (intervalID !== null) {
    clearInterval(intervalID); // Stop the previous interval
    intervalID = null; // Reset the interval ID
  }

  // Close the previous AudioContext if it exists
  if (context) {
    context.close(); // Close the previous AudioContext
    context = null; // Reset the context
  }

  // Create a new AudioContext for the new sequence
  context = new (window.AudioContext || window.webkitAudioContext)();

  const rhythm = metronomeSequence[rhythmType]; // Get rhythm pattern
  const sounds = soundFiles[drumType]; // Get sound files for the drum

  const intervalTime = (60 / bpm) * 1000; // Interval in ms based on BPM

  // Set an interval to loop through the sequence
  intervalID = setInterval(() => {
    const currentIndex = clickCount % rhythm.length; // Get current index in rhythm
    const hit = rhythm[currentIndex];

    // Map the rhythm hit (1 or 0) to the correct sound in the sound array
    const soundIndex = hit === 1 ? 1 : 0; // 1 = bell[1], 0 = bell[0]
    const sound = sounds[soundIndex];

    // Fetch and play the sound using AudioContext
    const source = context.createBufferSource();
    fetch(sound)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        context.decodeAudioData(data, (buffer) => {
          source.buffer = buffer;
          source.connect(context.destination);
          source.start();
        });
      });

    clickCount++; // Increment the count after playing a sound
  }, intervalTime);
}

// Function to stop the sequence
function stopSequence() {
  if (intervalID !== null) {
    clearInterval(intervalID); // Stop the interval
    intervalID = null; // Reset the interval ID
  }

  if (context) {
    context.close(); // Close the AudioContext when stopping
    context = null; // Reset the context
  }
}

export { startSequence, stopSequence };
