import React, { useState } from 'react';
import timer from './images/timer.svg';
import feature_daily from './images/features-daily.svg';
import feature_library from './images/features-library.svg';
import feature_track from './images/features-track.svg';
import Practice from "@pages/Admin/Home/Practice/Simple";
import { loadStripe } from '@stripe/stripe-js';
import { faqs } from './data';
import ContactForm from '@components/ContactForm';
import "./Home.scss";

//const stripePromise = loadStripe('pk_test_51O3BlGAazu6NxKfNTLUAScHpwcmMexFBjgPQ8XSnbsYLy3qVnodHPEOueguhbf8FYrNwrChmRvFAOReMLPhL1vYP00admzpJZY');
const stripePromise = loadStripe('pk_live_51PkK2CFC74FNBNDVHLLVAXbSfl4gbcifdglgPlWMzVFru54utK9IA3nvCIAHfxSSEnV43OxnqmMVfmJdWyU4amAO002B1LNP7b');

const Home = ({ children }) => {

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const createPayment = async (amount) => {
    // Initialize Stripe
    const stripe = await stripePromise;

    // Call your backend to create the Checkout session
    const response = await fetch('https://metromotivator-stripe.vercel.app/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }), // Pass the amount to your backend
    });

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network error, display the localized error message to your customer.
      alert(result.error.message);
    }
  };

  return(
    <>
      <div id="home">
        <div className="half">
          <h2>Energize Your Practice, Visualize Your Progress</h2>
          <span className="description">
            Us musicians know we really should work with a metronome to improve our timing, and the best way to learn how to play fast, is to…play slow! But spending time in the woodshed with a metronome doesn’t have to be boring! Visualize your progress and stay motivated with Metromotivator!
          </span>
        </div>
        <div className="half">
          <Practice />
        </div>
      </div>

      <div id="features">
        <div className="intro">
          <h3>Some of our features</h3>
          <span className="description">
            Metromotivator turns practice into progress with features that help you set goals, track improvements, and stay motivated. It's more than a metronome—it's your personal practice coach!
          </span>
        </div>
        <div className="feature-list">
          <div className="feature">
            <div className="f-image">
              <img src={ feature_daily } style={{ width: '100%' }} alt="" />
            </div>
            <div className="f-text">
              <h4>Robust Library of Drills</h4>
              <span className="description">
                Select exercises from a library of drills aimed at improving key techniques or create your own customized exercises! 
              </span>
            </div>
          </div>
          <div className="feature">
            <div className="f-image">
              <img src={ feature_library } style={{ width: '100%' }} alt="" />
            </div>
            <div className="f-text">
              <h4>Track Your Progress</h4>
              <span className="description">
                Metromotivator automatically records your practice time and the last BPM or % speed for each drill. This lets you see progress over time as you’re able to play drills cleaner and faster!
              </span>
            </div>
          </div>
          <div className="feature">
            <div className="f-image">
              <img src={ feature_track } style={{ width: '100%' }} alt="" />
            </div>
            <div className="f-text">
              <h4>Set Daily Goals</h4>
              <span className="description">
                Add training goals and monitor your progress to keep you on track!
              </span>
            </div>
          </div>
        </div>
      </div>

      <div id="plans">
        <div className="intro">
          <h3>Our plans</h3>
          <span className="description">
            Whether you're just starting out or need advanced tools for serious practice, we've got a plan for you! Choose the perfect package that suits your needs and take your playing to the next level with Metromotivator. Free one-week trial.
          </span>
        </div>
        <div className="subscriptions">
          <div className="sub active">
            <h3>Annual</h3>
            <span className="description">For serious musicians</span>
            <h2>$60</h2>
            <span className="description">per Year</span>
            <ul className="features">
              <li>Get two months free!</li>
            </ul>
            {/*<ul className="features">
              <li>Unlimited minutes</li>
              <li>Upload audio</li>
              <li>Track monthly progress</li>
              <li>Premium drills</li>
            </ul>*/}
            <a href="/register" className="btn-main center small no-width">Subscribe now</a>
          </div>
          <div className="sub">
            <h3>Monthly</h3>
            <span className="description">For the dabblers</span>
            <h2>$6</h2>
            <span className="description">per Month</span>
            {/*<ul className="features">
              <li>60 minutes max/day</li>
              <li>Upload tabs</li>
            </ul>*/}
            <a href="/register" className="btn-main center small no-width">Subscribe now</a>
          </div>
        </div>
      </div>

      <div id="testimonials">
        <h3>Testimonials</h3>
        <div className="reviews">
          <div className="review">
            <div className="rev-header">
              <div className={`rev-avatar`} style={{ backgroundImage: `url()` }}></div>
              <div className="rev-name">
                <span className="name">John S.</span>
                <div className="stars"></div>
              </div>
            </div>
            <div className="review-text">
              Metromotivator has completely transformed the way I practice! The visual progress tracker keeps me motivated to stay on tempo and see my improvement over time. Highly recommend!
            </div>
          </div>
          <div className="review">
            <div className="rev-header">
              <div className={`rev-avatar`} style={{ backgroundImage: `url()` }}></div>
              <div className="rev-name">
                <span className="name">Peggy Segers</span>
                <div className="stars"></div>
              </div>
            </div>
            <div className="review-text">
              I love how easy it is to set goals and track my progress. The library of drills is so helpful for targeting specific skills, and the custom exercise feature is a game changer.
            </div>
          </div>
          <div className="review">
            <div className="rev-header">
              <div className={`rev-avatar`} style={{ backgroundImage: `url()` }}></div>
              <div className="rev-name">
                <span className="name">Alex P.</span>
                <div className="stars"></div>
              </div>
            </div>
            <div className="review-text">
              This tool has made metronome practice much more engaging. The ability to upload your own screenshots to create custom workouts is amazing - I’ve never been so motivated to pick up the guitar and practice!
            </div>
          </div>
        </div>
      </div>

      <div id="faq">
        <h3>F.A.Q.</h3>
        <div className="faq-items">
          { faqs && faqs.map((item, key) => (
            <div className={`faq-item ${openIndex === key ? 'open' : ''}`} key={ key }>
              <h4 onClick={() => handleToggle(key)}>{ item.question }</h4>
              <span className="description">
                <div className="render-block">
                  { item.answer }
                </div>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div id="contact">
        <ContactForm />
      </div>
    </>
  )
}

export default Home;