import React, { useState, useEffect } from 'react';

import { getUserRole } from '@data/helpers';
import { getUser, setUser, setUserGoal } from '@data/redux/usersSlice';
import { useSelector, useDispatch } from 'react-redux';

import AdminProfile from './AdminProfile';
import AdminUsers from './AdminUsers';
import AdminPayments from './AdminPayments';
import AdminDrills from './AdminDrills';
import AdminLibrary from './AdminLibrary';

const Admin = () => {
  const [activeTab, setActiveTab] = useState('library');
  const user_store = useSelector(getUser);
  const [role, setRole] = useState('USER');

  useEffect(() => {
    setRole(getUserRole(user_store));
  }, []);

  return (
    <div id="history">
      <div className="box-wrapper">
        <div className="box">
          <div className="box-header">
            <span onClick={() => setActiveTab('library')} className={`tab ${(activeTab === 'library') ? 'active' : ''}`}>Drills Library</span>
            { (role === 'SUPERADMIN') && <span onClick={() => setActiveTab('users')} className={`tab ${(activeTab === 'users') ? 'active' : ''}`}>Users</span> }
            {/* (role === 'SUPERADMIN') && <span onClick={() => setActiveTab('payments')} className={`tab ${(activeTab === 'payments') ? 'active' : ''}`}>Payments</span> */}
            <span onClick={() => setActiveTab('profile')} className={`tab ${(activeTab === 'profile') ? 'active' : ''}`}>Profile</span>
            <span onClick={() => setActiveTab('your_drills')} className={`tab ${(activeTab === 'your_drills') ? 'active' : ''}`}>Your Drills</span>
          </div>
          <div className="box-content">
            {activeTab === 'users' && <AdminUsers />}
            {activeTab === 'library' && <AdminLibrary />}
            {activeTab === 'your_drills' && <AdminDrills />}
            {activeTab === 'profile' && <AdminProfile />}
            {activeTab === 'payments' && <AdminPayments />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
