import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Calendar from "@components/Calendar";
import LineChart from "@components/Charts/LineChart";
import moment from "moment";
import { getCollectionQuery, getCollectionQueryBetween } from '@data/firebase/firestore/getData';
import { formatMinutesFromSeconds, formatDateTime, formatTimeMinutes } from '@data/helpers';
import { getUser } from '@data/redux/usersSlice';

const BoxCalenderActivity = ({ handlePracticeWithSettings }) => {
  const [date, setDate] = useState(false);  // This stores the selected date
  const [calendarProps, setCalendarProps] = useState({ data_dates: [], star_dates: [] });
  const [weekData, setWeekData] = useState([]);
  const [showActivity, setShowActivity] = useState(false);  // Track if activity box should be shown
  const user_store = useSelector(getUser);
  const [drillHistory, setDrillHistory] = useState([]);
  const goal = user_store.goal ? user_store.goal / 60 : 0; // Assuming goal is in minutes

  // Fetch drill history
  useEffect(() => {
    getCollectionQuery('users/' + user_store.email + '/logs', (response) => {
      setDrillHistory(response);
    }, null, { field: 'datetime', order: 'desc' });
  }, [user_store.email]);

  // Fetch data for the current month or selected month
  useEffect(() => {
    const currentDate = date || moment();  // Use current date if no date is selected
    const startOfMonth = moment(currentDate, 'DD/MM/YYYY').startOf('month');
    const endOfMonth = moment(currentDate, 'DD/MM/YYYY').endOf('month');
    getCollectionQueryBetween('users/' + user_store.email + '/logs', (response) => {
        let data_dates = [];
        let star_dates = [];

        let logsByDate = {};

        response.forEach((log) => {
          const logDate = moment(log.datetime.toDate()).format('DD/MM/YYYY'); // Convert Firestore Timestamp to a JS Date and format it
          data_dates.push(logDate); // Add date to the data_dates array
          if (!logsByDate[logDate]) {
            logsByDate[logDate] = [];
          }
          logsByDate[logDate].push(log);
        });

        // Step 2: Remove duplicate dates
        data_dates = [...new Set(data_dates)];

        // Step 3: Calculate total seconds for each date and check against the goal
        Object.keys(logsByDate).forEach((date) => {
          const logsForDate = logsByDate[date];
          const totalSecondsForDate = logsForDate.reduce((acc, log) => acc + (log.seconds || 0), 0);
          const totalMinutesForDate = totalSecondsForDate / 60;

          // If total minutes for this date are >= goal, add the date to star_dates
          if (totalMinutesForDate >= goal) {
            star_dates.push(date);
          }
        });

        // Step 4: Set the calendar props with data_dates and star_dates
        setCalendarProps({ data_dates: data_dates, star_dates: star_dates });
      },
      {
        field: 'datetime',
        order: 'asc',
        value: { start: startOfMonth.toDate(), end: endOfMonth.toDate() }
      }
    );
  }, [date, user_store.email, goal]);

  // Fetch data for the selected week if a date is clicked
  useEffect(() => {
    if (date) {
      const startOfWeek = moment(date, 'DD/MM/YYYY').startOf('isoWeek');
      const endOfWeek = moment(date, 'DD/MM/YYYY').endOf('isoWeek');

      getCollectionQueryBetween(
        'users/' + user_store.email + '/logs',
        (response) => {
          const logsByDay = {};

          for (let i = 0; i < 7; i++) {
            const currentDay = startOfWeek.clone().add(i, 'days').format('DD/MM/YYYY');
            logsByDay[currentDay] = 0;
          }

          response.forEach((log) => {
            const logDate = moment(log.datetime.toDate()).format('DD/MM/YYYY');
            if (logsByDay[logDate] !== undefined) {
              logsByDay[logDate] += log.seconds;
            }
          });

          const formattedData = Object.keys(logsByDay).map((day, index) => {
            const totalMinutes = formatMinutesFromSeconds(logsByDay[day]);
            let star_dates = (totalMinutes >= goal) ? true : false;
            return {
              sublabel: moment(startOfWeek).add(index, 'days').format('ddd'),
              label: day,
              values: [totalMinutes],
              starred: star_dates
            };
          });

          setWeekData(formattedData);
        },
        {
          field: 'datetime',
          order: 'asc',
          value: { start: startOfWeek.toDate(), end: endOfWeek.toDate() }
        }
      );
    }
  }, [date, user_store.email]);

  // Handler for when a date is selected
  const handleDateSelection = (selectedDate) => {
    setDate(selectedDate);
    setShowActivity(true);  // Show the activity box when a date is selected
  };

  return (
    <div className="box-wrapper">
      {/* Calendar Section */}
      <div className="box calendar">
        <div className="box-content">
          <Calendar 
            setDate={handleDateSelection} 
            data_dates={calendarProps.data_dates} 
            star_dates={calendarProps.star_dates} 
          />
        </div>
      </div>

      {/* Show the Activity box only when a date is clicked */}
      {showActivity && date && (
        <div className="box activity">
          <div className="box-header">
            <span className="title">Recent Activity</span>
          </div>
          <div className="box-content">
            <LineChart chartdata={{
              colors: ['#19CFD9'],
              active: moment(date).format('MM/DD/YYYY'),
              data: weekData, 
              goal: goal
            }} />
          </div>
        </div>
      )}

      {/* Display recent history in table form */}
      {!date && (
        <div className="box">
          <div className="box-header">
            <span className="title">Recent History</span>
            <div className="action"><a href="/history">View full history</a></div>
          </div>
          <div className="box-content">
            <table>
              <thead>
                <tr>
                  <th className="hide-mobile" width="150">Date</th>
                  <th>Drill name</th>
                  <th className="hide-tablet">Total Min</th>
                  <th>BPM</th>
                  <th>Signature</th>
                  <th width="100"></th>
                </tr>
              </thead>
              <tbody>
                {drillHistory.slice(0, 6).map((val, key) => {
                  return (
                    <tr key={key}>
                      <td className="hide-mobile">{formatDateTime(val.datetime)}</td>
                      <td>{val.drill}</td>
                      <td className="hide-tablet">{formatTimeMinutes(val.seconds)}</td>
                      <td>{val.bpm}</td>
                      <td>{val.signature}</td>
                      <td>
                        <button
                          onClick={() => handlePracticeWithSettings(val.drill, val.bpm)}
                          className="btn-action"
                        >
                          Load Drill
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxCalenderActivity;
