import React, { useEffect, useRef, useState, useMemo } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import ZoomPlugin from 'wavesurfer.js/dist/plugins/zoom.esm.js';

import './AudioSurfer.scss';

const AudioSurfer = ({ audioFile, audioSettings, setAudioSettings }) => {
  const containerRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const regionsPlugin = useMemo(() => RegionsPlugin.create(), []);
  const zoomPlugin = useMemo(() => ZoomPlugin.create({ scale: 0.5, maxZoom: 100 }), []);

  useEffect(() => {
    if (!wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: containerRef.current,
        waveColor: 'rgb(0 82 255)',
        progressColor: 'rgb(179 204 255)',
        plugins: [regionsPlugin, zoomPlugin],
      });
      wavesurferRef.current.load(audioFile); // Load the external audio file

      let activeRegion = null;
      let loop = true;
      console.log(audioSettings);


      regionsPlugin.on('region-in', (region) => {
        activeRegion = region;
      });

      regionsPlugin.on('region-out', (region) => {
        if (activeRegion === region && loop) {
          region.play();
        }
      });

      regionsPlugin.on('region-update', updatedRegion => {
        setAudioSettings({ start: updatedRegion.start, end: updatedRegion.end });
        console.log('Updated region:', { start: updatedRegion.start, end: updatedRegion.end });
      });

      wavesurferRef.current.on('ready', () => {
        const region = regionsPlugin.addRegion({
          start: audioSettings.start,
          end: audioSettings.end,
          loop: true,
          color: 'rgba(0, 150, 255, 0.1)',
        });
        setAudioSettings({ start: region.start, end: region.end });
      });
    }

    const handleClick = () => {
      if (wavesurferRef.current.isPlaying()) {
        wavesurferRef.current.pause();
        setIsPlaying(false);
      } else {
        wavesurferRef.current.play();
        setIsPlaying(true);
      }
    };

    containerRef.current.addEventListener('click', handleClick);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('click', handleClick);
      }
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }
    };
  }, [regionsPlugin, audioFile]); // Re-load if the audioFile prop changes

  return (
    <div className="metrowave-wrapper">
      <div ref={containerRef} id="wave-audio" />
    </div>
  );
};

export default AudioSurfer;
