import React, { useState } from 'react';
import Tickbox from '@components/Tickbox'; // Import your custom Tickbox component
import "./MultiOptions.scss";

const Options = ({ label, options, onSelect }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (value) => {
    let updatedSelections = [...selectedOptions];

    if (updatedSelections.includes(value)) {
      // If the value is already in the selected options, remove it
      updatedSelections = updatedSelections.filter(option => option !== value);
    } else {
      // Otherwise, add it to the selected options
      updatedSelections.push(value);
    }

    setSelectedOptions(updatedSelections);
    onSelect(updatedSelections); // Pass the updated selections back to the parent component
  };

  return (
    <div className="multi-options">
      {label && <label>{label}</label>}
      <div className="m-options">
        {options.map((option, index) => (
          <div key={index} className="option">
            <Tickbox
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
              label={option.label}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Options;
