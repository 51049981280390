import React from "react";

import "./TextInput.scss";

function TextInput({ value, placeholder="", label, onChange, onBlur=false, keydown = false, disabled = false, type="text"}) {

  const handleKeyPress = (e) => {
    if(e.keyCode === 13){
      e.target.blur(); 
    }
  }

  const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"
    const newText = text?.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    )
    return newText?.toString().toLowerCase().trim().replace(/\s+/g, '-')
    .replace(/&/g, '-y-').replace(/[^\w-]+/g, '').replace(/--+/g, '-');
  }

  // Set autocomplete based on input type
  const getAutoComplete = () => {
    if (type === "email") return "email";
    if (type === "password") return "current-password";
    return "on"; // Default to 'off' for other input types
  }

  return (
    <div className={`main-input text in-${slugify(label ? label : '')}`}>
      { label ? (<label htmlFor={ 'in-' + slugify(label) }>{ label }</label>) : null }
      <input 
        type={ type } 
        className="text-input" 
        id={ '' + slugify(label ? label : type ? type : '') } 
        disabled={ disabled } 
        onBlur={() => onBlur && onBlur()} 
        name={type} 
        value={ value } 
        onChange={(e) => onChange(e.target.value)} 
        placeholder={ placeholder }  
        onKeyDown={(e) => keydown && handleKeyPress(e)}
        autoComplete={ getAutoComplete() }  // Add the autocomplete attribute here
      />
    </div>
  );
}

export default TextInput;
