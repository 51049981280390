import React, { useState } from 'react';
import TextInput from "@components/Inputs/TextInput";
import { createUser, loginUser } from "@data/firebase/auth";
import { setUser } from "@data/redux/usersSlice";
import { useDispatch } from 'react-redux';
import { checkPassword } from "@data/helpers";
import RadioOptions from '@components/RadioOptions';
import MultiOptions from '@components/MultiOptions';
import { useNavigate } from 'react-router-dom'; // Use React Router for navigation

const Register = ({ children }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [status, setStatus] = useState('register_form');
  const [error, setError] = useState(false);
  const [step, setStep] = useState('step_1');
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState([]); 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const OptionsQuestion_1 = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const OptionsQuestion_2 = [
    { value: 'guitar', label: 'Guitar' },
    { value: 'bass', label: 'Bass' },
    { value: 'piano', label: 'Piano' },
    { value: 'keys', label: 'Keys' },
    { value: 'drums', label: 'Drums' },
    { value: 'other', label: 'Other' }
  ];

  const nextStep = () => {
    setError(false);
    if (firstName === '' || lastName === '' || email === '' || password === '' || passwordRepeat === '') {
      setError('Please fill in all mandatory fields');
      setStatus('register_form');
    } else if (password !== passwordRepeat) {
      setError('Passwords do not match');
      setStatus('register_form');
    } else if (!checkPassword(password)) {
      setError('Weak password. Please choose at least 6 characters. Include one number,  and one symbol, and one capital letter.');
      setStatus('register_form');
    } else {
      setStatus('register_questions');
      setStep('step_2');
    }
  };


  const handleSignup = () => {
    setStatus('create_account');
    const user_data = {
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase(),
      answer_1: answer1,
      answer_2: answer2
    };
    console.log(user_data);
    createUser(email.toLowerCase(), password, user_data, (response) => {
      if (response.status === 'error') {
        setError(response.msg);
        setStep('step_1');
        setStatus('register_form');
      } else {
        loginUser(email.toLowerCase(), password, (res_login) => {
          setStatus('login_account');
          if (res_login.type === "success") {
            dispatch(setUser(res_login.data.user));
            navigate('/');
          } else {
            let error = (typeof res_login.data === 'undefined') ? 'Could not access database' : res_login.data;
            setError(error);
            setStep('step_1');
            setStatus('register_form');
          }
        });
      }
    });
  }


  return (
    <>
      <div id="main">
        <div className="main-content max-width">
          <div className="logo" />
          <h1>Transform your Musical Mastery</h1>
        </div>
      </div>
      {
        (step == 'step_1') && (
          <div id="content">
            <h2>Sign up <span className="small">1/2</span></h2>
            <h3>Dive into Skill-Enhancing Drills Across Instruments</h3>
            {status === 'register_form' && (
              <>
                <span className="description">Unlock Your Potential Across Instruments: Personalized Drills for Every Musician, and Every Skill Level.</span>
                <TextInput value={firstName} label="First Name" onChange={(e) => setFirstName(e)} placeholder="John" />
                <TextInput value={lastName} label="Last Name" onChange={(e) => setLastName(e)} placeholder="Doe" />
                <TextInput value={email} label="Email" onChange={(e) => setEmail(e)} placeholder="rocken@gmail.com" />
                <TextInput type="password" value={password} label="Password" onChange={(e) => setPassword(e)} placeholder="Some unique password" />
                <TextInput type="password" value={passwordRepeat} label="Confirm password" onChange={(e) => setPasswordRepeat(e)} placeholder="Confirm your password" />                
                <input type="submit" className="btn-main center" value="Next step" onClick={nextStep} />
                {error && <span className="error-msg">{error}</span>}
                <a href="/login" className="btn-main center transparent small">Or Sign in</a>
              </>
            )}
          </div>
        )
      }
      {
        (step == 'step_2') && (
          <div id="content">
            <h2>Sign up <span className="small">2/2</span></h2>
            <h3>A few questions before we start</h3>
            {status === 'register_questions' && (
              <>
                <RadioOptions
                  label="Have you practiced with a metronome before?"
                  options={OptionsQuestion_1}
                  onSelect={setAnswer1}
                />
                <MultiOptions
                  label="What instruments do you play"
                  options={OptionsQuestion_2}
                  onSelect={setAnswer2}
                />
                <input type="submit" className="btn-main center" value="Sign up now" onClick={handleSignup} />
                {error && <span className="error-msg">{error}</span>}
              </>
            )}
            {status === 'create_account' && <span className="status-msg">Creating account...</span>}
            {status === 'login_account' && <span className="status-msg">Account created. We login your account...</span>}
          </div>
        )
      }
      
    </>
  );
};

export default Register;
