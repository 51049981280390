import React from 'react';
import { formatTimeMinutes, formatDateTime } from '@data/helpers';

const History = ({ history }) => {

  return(
    <table>
      <thead>
        <tr>
          <th className="sortable hide-mobile">Date</th>
          <th className="sortable">Drill name</th>
          <th className="sortable">Signature</th>
          <th className="sortable">Total Min</th>
          <th className="sortable">BPM</th>
          <th className="sortable">Speed</th>
        </tr>
      </thead>
      <tbody>
        {history.length > 0 ? (
          history.map((val, key) => (
            <tr key={key}>
              <td className="hide-mobile">{formatDateTime(val.datetime)}</td>
              <td>{val.drill}</td>
              <td>{val.signature}</td>
              <td>{formatTimeMinutes(val.seconds)}</td>
              <td>{val.bpm}</td>
              <td>{val.speed}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No data available</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default History;