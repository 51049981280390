import React, { useState } from 'react';
import "./RadioOptions.scss";
const RadioOptions = ({ label, options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    onSelect(value); // Pass the selected value back to the parent component
  };

  return (
    <div className="radio-options">
      {label && <label>{label}</label>}
      <div className="r-options">
        {options.map((option, index) => (
          <div key={index} className="option">
            <input
              type="radio"
              id={option.value}
              name="options"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={handleRadioChange}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioOptions;