import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Slider, ThemeProvider, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import UiTheme from '@data/themes';
import { getCollection, getCollectionQuery } from '@data/firebase/firestore/getData';
import { getUser } from '@data/redux/usersSlice';
import { useSelector } from 'react-redux';
import { createDocument } from '@data/firebase/firestore/saveData';
import Loading from "@components/Loading";
import Tickbox from '@components/Tickbox';
import { v4 as uuidv4 } from 'uuid';

import sound2 from '@assets/files/sounds/sound-1.wav';
import sound1 from '@assets/files/sounds/sound-2.wav';
import sound4 from '@assets/files/sounds/sound-3.wav';
import sound3 from '@assets/files/sounds/sound-4.wav';

const Practice = ({ startbpm = 80, startname = '' }) => {
  const [value, setValue] = useState(80);
  const [isTimerPlaying, setIsTimerPlaying] = useState(false);
  const [isMetronomePlaying, setIsMetronomePlaying] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [drillName, setDrillName] = useState('');
  const [timerSeconds, setTimerSeconds] = useState(0);
  const audioContextRef = useRef(null);
  const clickSound1 = useRef(null);
  const clickSound2 = useRef(null);
  const clickSound3 = useRef(null);
  const clickSound4 = useRef(null);
  const metronomeTimerRef = useRef(null);
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);
  const elapsedTimeRef = useRef(0);
  const [timeText, setTimeText] = useState('00:00');
  const [signature, setSignature] = useState('quarter');
  const [drum, setDrum] = useState('bell');
  const clickCountRef = useRef(0);
  const [drills, setDrills] = useState([]);
  const user_store = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const [isNewDrill, setIsNewDrill] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const location = useLocation();
  const [autostartTimer, setAutostartTimer] = useState(false);

  useEffect(() => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    loadSounds();
  }, [location.state, user_store.email, startbpm, startname]);

  useEffect(() => {
    if (isMetronomePlaying) {
      startMetronome();
    } else {
      stopMetronome();
    }
    return () => {
      stopMetronome();
    };
  }, [isMetronomePlaying, value, signature]);

  useEffect(() => {
    if (isTimerPlaying) {
      startTimer();
    } else {
      stopTimer();
    }
    return () => {
      stopTimer();
    };
  }, [isTimerPlaying]);

  const loadSounds = async () => {
    try {
      const context = audioContextRef.current;

      const sound1Response = await fetch(sound1);
      if (!sound1Response.ok) throw new Error('Failed to load sound-1.wav');
      const sound1ArrayBuffer = await sound1Response.arrayBuffer();
      clickSound1.current = await context.decodeAudioData(sound1ArrayBuffer);

      const sound2Response = await fetch(sound2);
      if (!sound2Response.ok) throw new Error('Failed to load sound-2.wav');
      const sound2ArrayBuffer = await sound2Response.arrayBuffer();
      clickSound2.current = await context.decodeAudioData(sound2ArrayBuffer);

      const sound3Response = await fetch(sound3);
      if (!sound3Response.ok) throw new Error('Failed to load sound-3.wav');
      const sound3ArrayBuffer = await sound3Response.arrayBuffer();
      clickSound3.current = await context.decodeAudioData(sound3ArrayBuffer);

      const sound4Response = await fetch(sound4);
      if (!sound4Response.ok) throw new Error('Failed to load sound-4.wav');
      const sound4ArrayBuffer = await sound4Response.arrayBuffer();
      clickSound4.current = await context.decodeAudioData(sound4ArrayBuffer);

    } catch (error) {
      console.error('Error loading sounds:', error);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const startMetronome = () => {
    if (value > 300) setValue(300);
    if (value < 10) setValue(10);

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (autostartTimer) setIsTimerPlaying(true);

    clickCountRef.current = 0;
    const interval = (60 / value) * 1000;

    metronomeTimerRef.current = setInterval(() => {
      playClick();
    }, interval);
  };

  const stopMetronome = () => {
    if (metronomeTimerRef.current) {
      clearInterval(metronomeTimerRef.current);
      if (autostartTimer) setIsTimerPlaying(false);
    }
  };

  const playClick = () => {
    const context = audioContextRef.current;
    if (context) {
      const sequences = {
        bell: {
          quarter: [clickSound2.current, clickSound1.current, clickSound1.current, clickSound1.current],
          triplet: [clickSound2.current, clickSound1.current, clickSound1.current]
        },
        snare: {
          quarter: [clickSound4.current, clickSound3.current, clickSound3.current, clickSound3.current],
          triplet: [clickSound4.current, clickSound3.current, clickSound3.current]
        }
      };
      const sequence = sequences[drum][signature];
      const index = clickCountRef.current % sequence.length;
      const buffer = sequence[index];
      const source = context.createBufferSource();
      source.buffer = buffer;
      source.connect(context.destination);
      source.start();
      clickCountRef.current++;
    }
  };

  const startTimer = () => {
    setHasStarted(true);
    startTimeRef.current = Date.now() - elapsedTimeRef.current;
    timerRef.current = setInterval(() => {
      const elapsedTime = Date.now() - startTimeRef.current;
      elapsedTimeRef.current = elapsedTime;
      const seconds = Math.floor(elapsedTime / 1000);
      const minutes = String(Math.floor(seconds / 60)).padStart(2, '0');
      const remainingSeconds = String(seconds % 60).padStart(2, '0');
      setTimeText(`${minutes}:${remainingSeconds}`);
      setTimerSeconds(seconds);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  const handleReset = () => {
    stopTimer();
    setIsMetronomePlaying(false);
    setIsTimerPlaying(false);
    setHasStarted(false);
    elapsedTimeRef.current = 0;
    setTimeText('00:00');
    setTimerSeconds(0);
  };

  const filteredDrills = selectedCategory === 'All'
    ? drills
    : drills.filter(drill => drill.category === selectedCategory);

  return (
    loading ? <Loading loading={loading} /> :
      <ThemeProvider theme={UiTheme}>
        <div id="practice">
          <div className="timer-wrapper">
            <div className="timer">
              <div className="timer-box">
                <h3>Timer</h3>
                <div className="timer-text">
                  <button className={isTimerPlaying ? 'btn-pause' : 'btn-play'} onClick={() => setIsTimerPlaying(!isTimerPlaying)}>
                    {isTimerPlaying ? 'Stop' : 'Start'}
                  </button>
                  <h1>{timeText}</h1>
                  {hasStarted && !isTimerPlaying && (
                    <button className="btn-reset" onClick={handleReset}>Reset</button>
                  )}
                </div>
              </div>
              <div className="box metronome-box">
                <h3>Finger Independence</h3>
                <div className="timer-slider">
                  <Slider valueLabelDisplay="auto" value={value} onChange={handleSliderChange} min={10} max={300} />
                  <input className="in-value" type="number" value={value} onChange={e => setValue(Number(e.target.value))} />
                </div>
                <div className="select-drill-wrapper">
                  <Box className="drill-options">
                    <FormControl fullWidth>
                      <InputLabel id="select-drill-label">Drill</InputLabel>
                      <Select value={signature} label="Drill" onChange={(e) => setSignature(e.target.value)}>
                        <MenuItem value={'quarter'}>Quarter Notes</MenuItem>
                        <MenuItem value={'triplet'}>Triplets</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="select-drum-label">Drums</InputLabel>
                      <Select value={drum} label="Drum" onChange={(e) => setDrum(e.target.value)}>
                        <MenuItem value={'bell'}>Bell Drums</MenuItem>
                        <MenuItem value={'snare'}>Snare Drum</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <Tickbox checked={autostartTimer} onChange={() => setAutostartTimer(!autostartTimer)} label={(isMetronomePlaying ? 'Stop' : 'Start') + " timer with metronome."} />
                <div className={isMetronomePlaying ? 'btn-main big stop' : 'btn-main big play'} onClick={() => setIsMetronomePlaying(!isMetronomePlaying)}>
                  <i /><span>{isMetronomePlaying ? 'Stop' : 'Start'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
  );
};

export default Practice;
