import React, { useState, useEffect } from "react";
import "./Subscribe.scss";
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch
import { logoutUserData } from '@data/redux/usersSlice';
import { logoutUser } from "@data/firebase/auth";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const HomeLayout = ({children, page}) => {
  const [activeHeader, setActiveHeader] = useState(false);
  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 

  console.log(page);

  const handleScrollTo = (e, targetId) => {
    if(page === 'home'){
      e.preventDefault();
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth"
        });
        setTimeout(() => {
          window.scrollBy({ top: -200,  behavior: "smooth" });
        }, 500);
      }
    }
    
  };

  const logoutSubmit = async () => {
    try {
      await logoutUser();
      dispatch(logoutUserData()); 
      navigate('/'); 
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  useEffect(() => {
    document.body.classList.add('home-layout');
    return () => {
      document.body.classList.remove('home-layout');
    };
  }, []);

  return (
    <div id="home-wrapper">
      <header className={ (activeHeader ? `active` : ``) }>
        <div className="content">
          <div className="menu-wrapper">
            <a href="/home" className="logo"></a>
            <menu>
              <a href="/home#features" className="btn-menu" onClick={(e) => handleScrollTo(e, 'features')}>Features</a>
              <a href="/home#plans" className="btn-menu" onClick={(e) => handleScrollTo(e, 'plans')}>Plans</a>
              <a href="/home#testimonials" className="btn-menu" onClick={(e) => handleScrollTo(e, 'testimonials')}>Testimonials</a>
              <a href="/home#faq" className="btn-menu" onClick={(e) => handleScrollTo(e, 'faq')}>FAQ</a>
              <a href="/home#contact" className="btn-menu" onClick={(e) => handleScrollTo(e, 'contact')}>Contact</a>
            </menu>
            <a href="#" onClick={ () => logoutSubmit() } className="btn-menu btn-signin">Logout</a>
            <a href="/" className="btn-menu btn-main btn-signup btn-practice">Practice now!</a>
            <div className="nav-toggle" onClick={() => { setActiveHeader(!activeHeader); }}>
              <div></div><div></div><div></div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="content">
          { children }
        </div>
      </main>
    </div>
  );
}

export default HomeLayout;
