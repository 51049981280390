import React, { useState, useEffect } from "react";
import Loading from "@components/Loading";

import "./Gallery.scss";

function Gallery({ picture, open = false, setOpen }) {
  // Listen for the 'Esc' key press to close the gallery
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    // Attach the event listener when the component is mounted
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setOpen]); // Add setOpen to the dependency array

  return (
    <div id="gallery-wrapper" className={open ? "active" : ""}>
      <div className="gallery-black-bg" onClick={() => setOpen(false)}></div>
      <div className="btn-close" onClick={() => setOpen(false)} />
      <div
        className="gallery-image"
        onClick={() => setOpen(false)}
        style={{ backgroundImage: `url(${picture})` }}
      />
    </div>
  );
}

export default Gallery;
