import React, { useState } from 'react';
import TextInput from "@components/Inputs/TextInput";
import { loginUser } from "@data/firebase/auth";
import { setUser } from "@data/redux/usersSlice";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from '@data/firebase/auth'; // Import the reset password function

const ForgotPassword = ({ children }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const handleResetPassword = () => {
    sendPasswordResetEmail(email)
      .then(() => {
        alert('Password reset email sent successfully.');
        navigate('/login'); 
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        alert('Failed to send password reset email. Please try again.');
      });
  };

  const submitForget = async e => {
    e.preventDefault();
    setError('');
    if (validateFields()) {
      handleResetPassword()
    }
  };

  const validateFields = () => {
    if (!email) {
      setError('Please fill out all fields');
      return false;
    }
    return true;
  };

  return (
    <>
      <div id="main">
        <div className="main-content max-width">
          <div className="logo" />
          <h1>Transform your Musical Mastery</h1>
          <a href="#" className="btn-black">Watch Demo</a>
        </div>
      </div>
      <div id="content">
        <h2>Forgot Password</h2>
        <h3>Did you forget your password? No Problem we got you covered</h3>
        <span className="description">Fill in your email to get a password reset e-mail.</span>
        <form noValidate onSubmit={submitForget}>
          <TextInput value={email} onChange={(e) => setEmail(e)} placeholder="E-mail" />
          <input type="submit" className="btn-main center" value="Sign in" />
        </form>
        {error && <span className="error-msg">{error}</span>}
        <a href="/login" className="small btn-small">Remember again? Click here to login</a>
      </div>
    </>
  );
};

export default ForgotPassword;
