import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Calendar.scss";
import buildCalender from "./build";
import Left from "./images/left.svg";
import Right from "./images/right.svg";

const Calendar = ({ setDate, data_dates = [], star_dates = [] }) => {
  const [value, setValue] = useState(moment());
  const [calendar, setCalendar] = useState([]);
  const today = moment();
  const [noSelection, setNoSelection] = useState(true);

  useEffect(() => {
    setCalendar(buildCalender(value));  // Build the full month view
  }, [value]);

  function currMonthName() {
    return value.format("MMM");
  }

  function currYear() {
    return value.format("YYYY");
  }

  function prevMonth() {
    setValue(value.clone().subtract(1, "month"));
    setDate(value.clone().subtract(1, "month").format('DD/MM/YYYY'));
  }

  function nextMonth() {
    setValue(value.clone().add(1, "month"));
    setDate(value.clone().add(1, "month").format('DD/MM/YYYY'));
  }

  const changeDay = (day) => {
    setNoSelection(false);
    setDate(day.format('DD/MM/YYYY'));
    setValue(day);
  };

  const getDayClass = (day) => {
    const formattedDay = day.format('DD/MM/YYYY');
    let classes = "";

    if (data_dates.includes(formattedDay)) {
      classes += " marked";  // Add 'marked' if the day has data
    }
    if (star_dates.includes(formattedDay)) {
      classes += " star";  // Add 'star' if the total minutes >= goal
    }
    return classes;
  };

  return (
    <section className="c-calendar">
      <div className="c-cal-header">
        <div className="c-cal-monthyear">
          <div onClick={prevMonth}>
            <img src={Left} className="c-cal-left" alt="left arrow" />
          </div>
          <p className="c-cal-selected-month">{currMonthName()} {currYear()}</p>
          <div onClick={nextMonth}>
            <img src={Right} className="c-cal-right" alt="right arrow" />
          </div>
        </div>
      </div>

      <div className="c-calendar-full">
        <div className="c-calendar-week c-calendar-weekdays">
          <div className={'c-calendar-weekday'}>Mo</div>
          <div className={'c-calendar-weekday'}>Tu</div>
          <div className={'c-calendar-weekday'}>We</div>
          <div className={'c-calendar-weekday'}>Th</div>
          <div className={'c-calendar-weekday'}>Fr</div>
          <div className={'c-calendar-weekday'}>Sa</div>
          <div className={'c-calendar-weekday'}>Su</div>
        </div>
        {calendar.map((week, index) => (
          <div key={index} className="c-calendar-week">
            {week.map((day, index) => (
              <div 
                key={index}
                className={
                  !noSelection && value.isSame(day, "day")
                    ? "c-calendar-day-selected"
                    : value.isSame(day, "month")
                    ? `c-calendar-day${getDayClass(day)}`  // Add the marked and star classes here
                    : "c-calendar-day-othermonth"
                }
                onClick={() => changeDay(day)}
              >
                <div className={value.isSame(day, "day") ? "c-calendar-selected" : ""}>
                  <span className={today.isSame(day, 'day') ? 'today' : ""}>
                    {day.format('D')}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Calendar;
