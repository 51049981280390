import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Drills from "@components/Drills";
import { formatDateTime } from '@data/helpers';
import { getUser } from '@data/redux/usersSlice';
import { getCollectionQuery } from '@data/firebase/firestore/getData';
import Gallery from '@components/Gallery';

const BoxDrill = ({ handlePracticeWithSettings }) => {
  const [drillHistory, setDrillHistory] = useState([]);
  const [drillImage, setDrillImage] = useState(false);
  const user_store = useSelector(getUser);
  const [drillPicOverlay, setDrillPicOverlay] = useState(false);

  useEffect(() => {
    getCollectionQuery('users/' + user_store.email + '/logs', (response) => {
      setDrillHistory(response);
      getCollectionQuery('drills', (drill_response) => {
        if(drill_response.length){
          setDrillImage(drill_response[0].file)
        }
      }, { field: 'drill', value: response[0]?.drill });

    }, null, { field: 'datetime', order: 'desc' });
  }, [user_store.email]);

  return (
    drillHistory.length ? (
      <div className="box drills">
        { drillPicOverlay && <Gallery picture={ drillPicOverlay }  open={ drillPicOverlay } setOpen={ setDrillPicOverlay } /> }
        <div className="box-header">
          <span className="title">Jump back in</span>
        </div>
        <div className="box-content">
          { drillImage && (
            <div className="drill-wrapper">
              <img onClick={ () => setDrillPicOverlay(drillImage) } src={drillImage} widht="100%" alt="Logo" />
            </div>
          )}
          <div className="drill-details">
            <table width="100%">
              <thead>
                <tr>
                  <th>Last practiced</th>
                  <th>Drill</th>
                  <th>Fastest BPM</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{formatDateTime(drillHistory[0]?.datetime)}</td>
                  <td>{drillHistory[0]?.drill}</td>
                  <td>{drillHistory[0]?.bpm}</td>
                  <td><a href="#" className="btn-action small" onClick={() => handlePracticeWithSettings(drillHistory[0]?.drill, drillHistory[0]?.bpm)}>Keep going</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : <></>
  );
};

export default BoxDrill;
