import React, { useState, useEffect } from "react";
import "./Home.scss";

const HomeLayout = ({children}) => {
  const [activeHeader, setActiveHeader] = useState(false);

  const handleScrollTo = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth"
      });
      setTimeout(() => {
        window.scrollBy({ top: -200,  behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    document.body.classList.add('home-layout');
    return () => {
      document.body.classList.remove('home-layout');
    };
  }, []);

  return (
    <div id="home-wrapper">
      <header className={ (activeHeader ? `active` : ``) }>
        <div className="content">
          <div className="menu-wrapper">
            <a href="#home" className="logo"></a>
            <menu>
              <a href="#features" className="btn-menu" onClick={(e) => handleScrollTo(e, 'features')}>Features</a>
              <a href="#plans" className="btn-menu" onClick={(e) => handleScrollTo(e, 'plans')}>Plans</a>
              <a href="#testimonials" className="btn-menu" onClick={(e) => handleScrollTo(e, 'testimonials')}>Testimonials</a>
              <a href="#faq" className="btn-menu" onClick={(e) => handleScrollTo(e, 'faq')}>FAQ</a>
              <a href="#contact" className="btn-menu" onClick={(e) => handleScrollTo(e, 'contact')}>Contact</a>
            </menu>
            <a href="/login" className="btn-menu btn-signin">Login</a>
            <a href="/register" className="btn-menu btn-main btn-signup">Sign Up</a>
            <div className="nav-toggle" onClick={() => { setActiveHeader(!activeHeader); }}>
              <div></div><div></div><div></div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="content">
          { children }
        </div>
      </main>
    </div>
  );
}

export default HomeLayout;
